
.forgotPassword {
 
    background-color: #FFE5E4;
    width: 280px;
    height: 380px;
    border-radius: 20px;
    /* box-shadow: 0px 4px 159px rgba(255, 255, 255, 0.25); */
    padding: 20px;
    transition: all 0.3s ease-in-out;
    color: #ED6F6D;
    border-radius: 30px;
}

.forgotPassword:hover{
    
    box-shadow: 0px 4px 55px rgba(190, 47, 150, 0.205);
}

.logoContainer{
   height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
}

.logo {
    object-fit: contain;
    height: 100px;
    width: 100px;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
