.buttonContainer {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    padding: 7px 0px;
}


.button {
    background-color: #ED6F6D;
    width: 95%;
    border-radius: 5px;
    border: none;
    outline: none;
    color: white;
    padding: 10px 10px;
    transition: all 0.2s ease-in-out;
    font-family: 'Crimson Pro';
}

.button:hover{
    
    background-color:#ED6F6D;
    margin: 0px 20px;
    cursor: pointer;
}

